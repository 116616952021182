<template>
  <GameLists />
</template>

<script>
import { GAME_LISTS_VIEW } from '@/constants'
import GameLists from '@/components/GameLists'

export default {
  name: GAME_LISTS_VIEW,
  components: {
    GameLists,
  },
}
</script>
